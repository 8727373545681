import React from 'react';
import { connect } from 'react-redux';
import { fetchNurseNotesForStay } from '../store/actions';
import { getNurseNotesForStaySelector } from '../store/selectors';
import moment from 'moment';
import Table from '../common/Table';

const config = {
    columns: [{
        title: 'Date',
        key: 'date',
        transformer: (data) => moment(data).format('M/DD/YYYY'),
        sortValue: (data) => moment(data.date).unix(),
        sortable: true
    }, {
        title: 'Note Type',
        key: 'type',
        sortable: true
    }, {
        title: 'Note',
        key: 'note',
        sortable: true
    }, {
        title: 'Author',
        key: 'author',
        sortable: true
    }],
    defaultSort: {
        column: 0,
        direction: 'descending'
    }
};

class NurseNotes extends React.Component {
    componentDidMount() {
        const { stay, fetchNurseNotesForStay } = this.props;
        stay && fetchNurseNotesForStay(stay.id);
    }
    render() {
        const { nurseNotes = [] } = this.props;
        return nurseNotes.length > 0 ? 
            <Table data={nurseNotes} config={config} /> : 'There are no notes for this patient.';
    }
}
function mapStateToProps(state, ownProps) {
    const { stay } = ownProps;
    return {
        nurseNotes: stay && stay.id ? getNurseNotesForStaySelector(stay.id)(state) : []
    };
}

export default connect(mapStateToProps, { fetchNurseNotesForStay })(NurseNotes);