import React from 'react';
import moment from 'moment';
import Table from '../common/Table';
import PatientLink from './PatientLink';

export const config = {
    columns: [{
        title: 'Admit Date',
        key: 'admitDate',
        transformer: (data) => moment(data).format('M/DD/YYYY'),
        sortValue: (data) => moment(data.admitDate).unix(),
        sortable: true
    }, {
        title: 'Patient',
        transformer: (data) => <PatientLink stayId={data.id} />,
        sortable: true,
        sortKey: 'lastName'
    }, {
        title: 'Payer',
        key: 'insurancePayerName',
        sortable: true
    }, {
        title: 'Status',
        key: 'status',
        sortable: true
    }, {
        title: 'Location',
        key: 'location',
        sortable: true
    }],
    defaultSort: {
        column: 0,
        direction: 'descending'
    },
    search: {
        keys: [{
            name: 'lastName',
            weight: 0.3
        }, {
            name: 'firstName',
            weight: 0.3
        }, {
            name: 'insurancePayerName',
            weight: 0.1
        }, {
            name: 'status',
            weight: 0.1
        }, {
            name: 'location',
            weight: 0.2
        }]
    }
};

export default function StaysList(props) {
    const { stays = [] } = props;
    return <Table config={config} data={stays} />;
};