export const CREATE_UPDATE = 'CREATE_UPDATE';
export const FETCH_ALERTS = 'FETCH_ALERTS';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_STAY = 'FETCH_STAY';
export const FETCH_STAYS = 'FETCH_STAYS';
export const FETCH_THERAPY_EVENTS = 'FETCH_THERAPY_EVENTS';
export const FETCH_NURSE_NOTES = 'FETCH_NURSE_NOTES';
export const FETCH_INSURANCE_PLANS = 'FETCH_INSURANCE_PLANS';
export const FETCH_INSURANCE_PLAN = 'FETCH_INSURANCE_PLAN';
export const FETCH_UPDATES = 'FETCH_UPDATES';
export const SET_ALERTS_DATE_FILTER = 'SET_ALERTS_DATE_FILTER';
export const SET_STAY_DATE_FILTER = 'SET_STAY_DATE_FILTER';
export const SET_UPDATE_DATE_FILTER = 'SET_UPDATE_DATE_FILTER';
export const UPDATE_STAY = 'UPDATE_STAY';