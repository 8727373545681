import React from 'react';
import { SingleDatePicker as Picker } from 'react-dates';

export default class SingleDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = { focused: false };
    }
    render() {
        return (
            <Picker
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                noBorder
                small
                showDefaultInputIcon
                numberOfMonths={1}
                {...this.props}
            />
        )
    }
}