import { FETCH_ALERTS, SET_ALERTS_DATE_FILTER } from "../actions/types";
import { normalize } from './index';
import moment from 'moment';

const initialState = {
    all: {},
    filter: {
        startDate: moment().subtract(3, 'month').hour(0).minute(0).second(0).millisecond(0),
        endDate: null
    }
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_ALERTS_DATE_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate
                }
            };
        case `${FETCH_ALERTS}_FULFILLED`:
            return {
                ...state,
                all: {
                    ...state.all,
                    ...normalize(action.payload.data)
                }
            };
        default:
            return state;
    }
}
