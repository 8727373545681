import React from 'react';

const ALERT_TYPES = [
    {
        name: 'Reimbursement',
        color: '#019999'
    },
    {
        name: 'Cost',
        color: '#004859'
    }, {
        name: 'Clinical',
        color: '#00ccc2'
    }
];

export default function AlertsSummary({ alerts = [] }) {
    return (
        <div className='row'>
            {ALERT_TYPES.map(type => {
                const count = alerts.filter(alert => alert.type === type.name).length;
                return (
                    <div className='col-sm-4' key={type.name}>
                        <div className='text-center p-xs' style={{
                            color: 'white',
                            backgroundColor: type.color
                        }}>
                            <h1>{count}</h1>
                            <h4>{type.name} Alert{count !== 1 && 's'}</h4>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}