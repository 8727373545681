import React from 'react';

export default ({ children, heading, className, style }) => {
    return (
        <div className={`hpanel${className ? ` ${className}` : ''}`} style={style}>
            {heading && <div className='panel-heading'></div>}
            {children && <div className='panel-body'>
                {children}
            </div>}
        </div>
    );
};