import React from 'react';
import moment from 'moment';
import Table from '../common/Table';
import PatientLink from '../patient/PatientLink';

export const config = {
    defaultSort: {
        column: 0,
        direction: 'descending'
    },
    search: {
        keys: [
            {
                name: 'stay.firstName',
                weight: 0.25
            },
            {
                name: 'stay.lastName',
                weight: 0.25
            },
            {
                name: 'formattedDueDate',
                weight: 0.1
            },
            {
                name: 'stay.insurancePayerName',
                weight: 0.2
            }, 
            {
                name: 'stay.billingInsurancePlan.careLevel',
                weight: 0.1
            }, 
            { 
                name: 'stay.deliveredInsurancePlan.careLevel',
                weight: 0.1
            }
        ]
    },
    columns: [{
        title: 'Date',
        key: 'formattedDueDate',
        sortValue: (data) => moment(data.dueDate).unix(),
        sortable: true
    }, {
        title: 'Patient',
        transformer: (data) => <PatientLink stayId={data.stayId} />,
        sortKey: 'stay.lastName',
        sortable: true
    }, {
        title: 'Payer',
        key: 'stay',
        transformer: ({ insurancePayerName }) => insurancePayerName,
        sortKey: 'stay.insurancePayerName',
        sortable: true
    }, {
        title: 'Billing CL',
        key: 'stay',
        transformer: ({ billingInsurancePlan = {} }) => billingInsurancePlan.careLevel,
        sortKey: 'stay.billingInsurancePlan.careLevel',
        sortable: true
    }, {
        title: 'Delivered CL',
        key: 'stay',
        transformer: ({ deliveredInsurancePlan = {} }) => deliveredInsurancePlan.careLevel,
        sortKey: 'stay.deliveredInsurancePlan.careLevel',
        sortable: true
    }, {
        title: 'Length of Stay',
        key: 'stay',
        transformer: ({ lengthOfStay }) => `${lengthOfStay} days`,
        sortKey: 'stay.lengthOfStay',
        sortable: true
    }, {
        title: 'Cost Alerts',
        key: 'stay',
        transformer: ({ numCostAlerts }) => numCostAlerts,
        sortKey: 'stay.numCostAlerts',
        sortable: true
    }, {
        title: 'Clinical Alerts',
        key: 'stay',
        transformer: ({ numClinicalAlerts }) => numClinicalAlerts,
        sortKey: 'stay.numClinicalAlerts',
        sortable: true
    }]
};

export default function UpdatesList(props) {
    const { updates = [] } = props;
    const updateList = updates.map(update => ({ ...update, formattedDueDate: moment(update.dueDate).format('M/DD/YYYY')}));
    return (
        <Table config={config} 
            data={updateList}
            rowStyler={(data) => {
                const { billingInsurancePlanId, deliveredInsurancePlanId } = data;
                if (billingInsurancePlanId !== deliveredInsurancePlanId) {
                    return { className: 'warning' };
                }
                return {};
            }}
        />
    );
};
