import React from 'react';
import moment from 'moment';
import LabeledField from '../common/LabeledField';

export default function PatientInfo(props) {
    const {
        primaryDiagnosis,
        location,
        admitDate,
        referralSource,
        billingInsurancePlan
    } = props;
    const information = [{
        label: 'Diagnosis',
        value: primaryDiagnosis
    }, {
        label: 'Location',
        value: location
    }, {
        label: 'Admitted',
        value: moment(admitDate).format('M/DD/YYYY')
    }, {
        label: 'Referral Source',
        value: referralSource
    }];
    billingInsurancePlan && information.push(...[{
        label: 'Revenue',
        value: `$${billingInsurancePlan.dailyReimbursementAmount}/day`
    }, {
        label: 'Current Payer',
        value: `${billingInsurancePlan.payerName} ${billingInsurancePlan.careLevel}`
    }])
    return <>
        <h2 className='h5'><strong>Patient Info</strong></h2>
        <div className='hpanel'>
            <div className='panel-body'>
                {information.map(({ label, value }) => (
                    <LabeledField key={label} label={label}>
                        {value}
                    </LabeledField>
                ))}
            </div>
        </div>
    </>;
};