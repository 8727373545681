import React from 'react';
import Panel from '../common/Panel';
import LabeledField from '../common/LabeledField';

export class AlertSummary extends React.Component {
    render() {
        const redWarningColor = '#ce4844'
        const { billingInsurancePlan, deliveredInsurancePlan } = this.props;
        if (billingInsurancePlan && deliveredInsurancePlan && billingInsurancePlan.id !== deliveredInsurancePlan.id) {
            return (
                <Panel className='hpanel-danger m-t-sm' style={{ borderLeft: `5px solid ${redWarningColor}`}}>
                    <div style={{ display: 'flex' }}>
                        <i className='fa fa-exclamation-triangle fa-2x m-r-sm' style={{ color: redWarningColor }}></i>
                        <div>
                            <span style={{ color: redWarningColor }} className='h4'>Therapy level delivery exceeds care level requirement.</span>
                            <LabeledField className='h5' label='Billed Level' labelBasis='150px'>
                                {billingInsurancePlan.careLevel} (${billingInsurancePlan.dailyReimbursementAmount}/day)
                            </LabeledField>
                            <LabeledField className='h5' label='Delivered Level' labelBasis='150px'>
                                {deliveredInsurancePlan.careLevel} (${deliveredInsurancePlan.dailyReimbursementAmount}/day)
                            </LabeledField>
                        </div>
                    </div>
                </Panel>
            );
        }
        return null;
    }
};

export default AlertSummary;