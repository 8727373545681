import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchStay, fetchInsurancePlans } from './store/actions';
import { useFullName } from './utilities';
import AlertSummary from './patient/AlertSummary';
import PatientInfo from './patient/PatientInfo';
import { Switch, Route, NavLink } from 'react-router-dom';
import ServiceSummary from './patient/ServiceSummary';
import Alerts from './patient/Alerts';
import NurseNotes from './patient/NurseNotes';
import Therapy from './patient/Therapy';
import Orders from './patient/Orders';
import Panel from './common/Panel';

export class PatientPage extends Component {
    constructor(props) {
        super(props);
        this.requestedPlans = [];
        this.fetchInsurancePlansAsNeeded = this.fetchInsurancePlansAsNeeded.bind(this);
    }
    componentDidMount() {
        const { fetchStay, id, stay } = this.props;
        !stay && typeof fetchStay === 'function' && fetchStay(id)
        this.fetchInsurancePlansAsNeeded();
    }

    componentDidUpdate(prevProps, prevState) {
        const { stay } = this.props;
        if (!prevProps.stay && stay) {
            this.fetchInsurancePlansAsNeeded();
        }
    }

    fetchInsurancePlansAsNeeded() {
        const { fetchInsurancePlans, stay } = this.props;
        stay && stay.insurancePayerName && typeof fetchInsurancePlans === 'function' && fetchInsurancePlans({ payerName: stay.insurancePayerName });
    }

    get tabs() {
        const { match: { url } = {}} = this.props;
        return [{
            title: 'Service Summary',
            path: url,
            exact: true,
            component: ServiceSummary
        }, {
            title: 'Alerts',
            path: `${url}/alerts`,
            component: Alerts
        }, {
            title: 'Nurse Notes',
            path: `${url}/nurse-notes`,
            component: NurseNotes
        }, {
            title: 'Therapy',
            path: `${url}/therapy`,
            component: Therapy
        }, {
            title: 'Orders',
            path: `${url}/orders`,
            component: Orders
        }];
    }

    render() {
        const { stay, billingInsurancePlan, deliveredInsurancePlan } = this.props;
        const navigationTabs = this.tabs;
        return stay ? (
            <>
                <Panel style={{margin: '-16px -16px 0 -16px'}}>
                    <h1>{useFullName(stay)}</h1>
                </Panel>
                <AlertSummary stay={stay} billingInsurancePlan={billingInsurancePlan} deliveredInsurancePlan={deliveredInsurancePlan} />
                <PatientInfo {...stay} billingInsurancePlan={billingInsurancePlan} deliveredInsurancePlan={deliveredInsurancePlan} />
                <Switch>
                    {navigationTabs.map(tab => (
                    <Route key={tab.path} {...tab} component={props => {
                        const { match } = props;
                        return (
                            <>
                                <ul className="nav nav-tabs">
                                    {navigationTabs.map(navTab => {
                                        return (
                                            <li role="presentation"
                                                key={navTab.path}
                                                className={match.path === navTab.path ? 'active' : ''}>
                                                <NavLink to={navTab.path} exact>{navTab.title}</NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className='hpanel tab-pane'>
                                    <div className='panel-body'>
                                        <tab.component {...props} stay={stay} />
                                    </div>
                                </div>
                            </>
                        );
                    }} />))}
                </Switch>
            </>
        ) : null;
    }
}

function mapStateToProps(_, initialProps) {
    const { match: { params: { id } = {} } = {} } = initialProps;
    return state => {
        const stay = state.stays.all[id];
        return {
            id,
            stay,
            billingInsurancePlan: stay && stay.billingInsurancePlanId && state.insurancePlans.all[stay.billingInsurancePlanId],
            deliveredInsurancePlan: stay && stay.billingInsurancePlanId && state.insurancePlans.all[stay.deliveredInsurancePlanId]
        }
    };
}

export default connect(mapStateToProps, {
    fetchStay,
    fetchInsurancePlans
})(PatientPage);