import React from 'react';
import { connect } from 'react-redux';
import { fetchAlertsForStay } from '../store/actions';
import { getAlertsForStaySelector } from '../store/selectors';
import moment from 'moment';
import Table from '../common/Table';

const config = {
    columns: [
        {
            title: 'Date',
            key: 'date',
            transformer: (date) => moment(date).format('M/DD/YYYY'),
            sortValue: (data) => moment(data.date).unix(),
            sortable: true
        },
        {
            title: 'Type',
            key: 'type',
            sortable: true
        },
        {
            title: 'Category',
            key: 'category',
            sortable: true
        },
        {
            title: 'Description',
            key: 'description',
            sortable: true
        }
    ],
    defaultSort: {
        column: 0,
        direction: 'descending'
    }
};

class Alerts extends React.Component {
    componentDidMount() {
        const { stay, fetchAlertsForStay } = this.props;
        stay && fetchAlertsForStay(stay.id);
    }
    render() {
        const { alerts = [] } = this.props;
        return alerts.length > 0 ? 
            <Table config={config} data={alerts} /> : 'There are no alerts for this patient.';
    }
}
function mapStateToProps(state, ownProps) {
    const { stay } = ownProps;
    return {
        alerts: stay && stay.id ? getAlertsForStaySelector(stay.id)(state) : []
    };
}

export default connect(mapStateToProps, { fetchAlertsForStay })(Alerts);