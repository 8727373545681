import React from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, ReferenceLine, Label, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { connect } from 'react-redux';
import { generateTherapyGraphForStaySinceDate } from '../../store/selectors';

export class TherapyGraph extends React.Component {
    render() {
        const {
            therapyEventData,
            stay: { admitDate },
            billingInsurancePlan: { therapyMinutesPerDay } = {},
            width = 500,
            height = 250
        } = this.props;
        const admitMoment = moment(admitDate);
        const data = therapyEventData.map(({ date, ...rest }) => {
            const tickDate = moment(date);
            return {
                date,
                dayInStay: Math.ceil(moment.duration(tickDate.diff(admitMoment)).asDays()),
                ...rest
            };
        });
        return (
            <ResponsiveContainer width={width} height={height}>
                <BarChart data={data}>
                    <CartesianGrid stroke='1' />
                    <XAxis dataKey="dayInStay" >
                        <Label value="Days In Stay" offset={0} position="insideBottom" />
                    </XAxis>
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="minutes" fill="#8884d8" />
                    <ReferenceLine y={therapyMinutesPerDay} stroke="red" strokeDasharray="3 3" >
                        <Label position="insideRight" value="Max Reimbursement" />
                    </ReferenceLine>
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { stay } = ownProps;
    return {
        therapyEventData: stay && stay.id ? generateTherapyGraphForStaySinceDate(stay.id, stay.admitDate)(state) : [],
        billingInsurancePlan: stay && stay.billingInsurancePlanId && state.insurancePlans.all[stay.billingInsurancePlanId]
    };
}

export default connect(mapStateToProps)(TherapyGraph);