import { CREATE_UPDATE, FETCH_UPDATES, SET_UPDATE_DATE_FILTER } from '../actions/types';
import { normalize } from './index';
import moment from 'moment';

const initialState = {
  all: {},
  filter: {
    startDate: moment(),
    endDate: null
  },
  nextUpdateId: 5,
};

function computeNewUpdateState(state, updatesData) {
  const updates = updatesData.map(({stay, ...rest}) => {
    return {
      ...rest,
      stayId: stay.id
    };
  });
  return {
    ...state,
    all: normalize(updates)
  };
  
}

export default function(state = initialState, action) {
  switch(action.type) {
    case SET_UPDATE_DATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate
        }
      };
    case `${FETCH_UPDATES}_FULFILLED`:
      return computeNewUpdateState(state, action.payload.data);
    case `${CREATE_UPDATE}_FULFILLED`:
      const newStay = {
        ...action.payload,
        status: "Not Completed",
        id: `update${state.nextUpdateId}`
      };
      const newState = {
        ...state,
        nextUpdateId: state.nextUpdateId + 1
      };
      newState.all[newStay.id] = newStay;
      return newState;
    default:
      return state;
  }
}
