import React from 'react';
import { connect } from 'react-redux';
import { getTherapyNotesForStaySelector } from '../../store/selectors';
import Table from '../../common/Table';
import moment from 'moment';

const config = {
    columns: [{
        title: 'Date',
        key: 'date',
        transformer: (date) => moment(date).format('MM/DD/YYYY'),
        sortValue: (data) => moment(data.date).unix(),
        sortable: true
    }, {
        title: 'Notes',
        key: 'notes',
        sortable: true
    }],
    defaultSort: {
        column: 0,
        direction: 'descending'
    }
};

function TherapyList(props) {
    const { therapyEvents } = props;
    return (
        <Table data={therapyEvents} config={config} />
    );
}

function mapStateToProps(state, ownProps) {
    const { stay } = ownProps;
    return {
        therapyEvents: stay && stay.id ? getTherapyNotesForStaySelector(stay.id)(state) : []
    };
}

export default connect(mapStateToProps)(TherapyList);