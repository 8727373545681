import React from 'react';
import { connect } from 'react-redux';
import { fetchTherapyEventsForStay } from '../../store/actions';
import TherapyGraph from './Graph';
import TherapyList from './List';

export class Therapy extends React.Component {
    componentDidMount() {
        const { stay, fetchTherapyEventsForStay } = this.props;
        stay && fetchTherapyEventsForStay(stay.id);
    }
    render() {
        const { stay } = this.props;
        return (
            <div className='row'>
                <div className='col-md-6'>
                    <TherapyGraph stay={stay} width="100%" />
                </div>
                <div className='col-md-6'>
                    <TherapyList stay={stay} />
                </div>
            </div>
        );
    }
}

export default connect(null, { fetchTherapyEventsForStay })(Therapy);