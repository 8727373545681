import { combineReducers } from 'redux';
import alertsReducer from './alerts';
import insurancePlansReducer from './insurancePlans';
import ordersReducer from './orders';
import staysReducer from './stays';
import updatesReducer from './updates';
import nurseNotesReducer from './nurseNotes';
import therapyEventsReducer from './therapyEvents';

export const normalize = (data = [], { key = 'id' } = {}) => {
    return data.reduce((result, obj) => {
        return {
            ...result,
            [obj[key]]: obj
        };
    }, {});
}

export default combineReducers({
    alerts: alertsReducer,
    insurancePlans: insurancePlansReducer,
    orders: ordersReducer,
    stays: staysReducer,
    nurseNotes: nurseNotesReducer,
    therapyEvents: therapyEventsReducer,
    updates: updatesReducer
})
