import { FETCH_STAY, FETCH_STAYS, FETCH_UPDATES, SET_STAY_DATE_FILTER, UPDATE_STAY } from '../actions/types';
import { normalize } from './index';

const initialState = {
    all: {},
    filter: {
        startDate: null,
        endDate: null
    }
};

export default function(state = initialState, action) {
    switch(action.type) {
        case SET_STAY_DATE_FILTER:
        return {
            ...state,
            filter: {
                ...state.filter,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }
        };
        case `${FETCH_UPDATES}_FULFILLED`:
            const stays = action.payload.data.map(update => {
                return update.stay;
            }).filter(e => !!e);
            return {
                ...state,
                all: {
                    ...state.all,
                    ...normalize(stays)
                }
            };
        case `${FETCH_STAYS}_FULFILLED`:
            return {
                ...state,
                all: normalize(action.payload.data)
            };
        case `${FETCH_STAY}_FULFILLED`:
        case `${UPDATE_STAY}_FULFILLED`:
            return {
                ...state,
                all: {
                    ...state.all,
                    [action.payload.data.id]: action.payload.data
                }
            };
        default:
            return state;
    }
}
