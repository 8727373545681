import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UpdatesList from './updates/UpdatesList';
import { updatesListSelector } from './store/selectors';
import { fetchUpdates, fetchInsurancePlans, setUpdateDateFilter } from './store/actions';
import CreateUpdateButton from './updates/CreateUpdate';
import { DateFilter } from './common/DateFilter';

export class UpdatesPage extends Component {
  componentDidMount() {
    const { fetchUpdates, fetchInsurancePlans, startDate } = this.props;
    typeof fetchUpdates === 'function' && fetchUpdates({ startDate: startDate && startDate.format('YYYY-MM-DD') });
    typeof fetchInsurancePlans === 'function' && fetchInsurancePlans();
  }
  
  render() {
    return (
      <>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }} className="m-b-sm">
          <h1 className='h2 text-primary' style={{ flex: '1 0 auto' }}>Upcoming Updates</h1>
          <div className='m-r-sm'>
            <CreateUpdateButton />
          </div>
          <DateFilter endDate={this.props.endDate}
            startDate={this.props.startDate}
            setDateFilter={this.props.setUpdateDateFilter} />
        </div>
        <div className='hpanel'>
          <div className='panel-body no-padding'>
            <UpdatesList {...this.props} />
          </div>
        </div>
      </>
    );
  }
};

UpdatesPage.propTypes = {
  updates: PropTypes.array
};

function mapStateToProps(state) {
  return {
    updates: updatesListSelector(state),
    startDate: state.updates.filter.startDate,
    endDate: state.updates.filter.endDate
  };
}

export default connect(mapStateToProps, { fetchUpdates, fetchInsurancePlans, setUpdateDateFilter })(UpdatesPage);
