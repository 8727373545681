import React from 'react';
import moment from 'moment';
import Table from './common/Table';
import PatientLink from './patient/PatientLink';

export const config = {
    columns: [{
        title: 'Date',
        key: 'date',
        transformer: (data) => moment(data).format('M/DD/YYYY'),
        sortValue: (data) => moment(data.date).unix(),
        sortable: true
    }, {
        title: 'Patient',
        transformer: (alert) => {
            return <PatientLink stayId={alert.stayId} />
        },
        sortKey: 'stay.lastName',
        sortable: true
    }, {
        title: 'Type',
        key: 'type',
        sortable: true
    }, {
        title: 'Category',
        key: 'category',
        sortable: true
    }, {
        title: 'Description',
        key: 'description',
        sortable: true
    }],
    defaultSort: {
        column: 0,
        direction: 'descending'
    },
    search: {
        keys: [{
            name: 'stay.lastName',
            weight: 0.35
        }, {
            name: 'stay.firstName',
            weight: 0.35
        },
        {
            name: 'type',
            weight: 0.125
        },
        {
            name: 'category',
            weight: 0.125
        },
        {
            name: 'description',
            weight: 0.05
        }]
    }
};

export default function AlertsList(props) {
    const { alerts = [] } = props;
    return <Table config={config} data={alerts} />;
};