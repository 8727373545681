import {
  CREATE_UPDATE,
  FETCH_ALERTS,
  FETCH_ORDERS,
  FETCH_STAY,
  FETCH_STAYS,
  FETCH_THERAPY_EVENTS,
  FETCH_INSURANCE_PLAN,
  FETCH_INSURANCE_PLANS,
  FETCH_NURSE_NOTES,
  FETCH_UPDATES,
  SET_UPDATE_DATE_FILTER,
  SET_ALERTS_DATE_FILTER,
  SET_STAY_DATE_FILTER,
  UPDATE_STAY
} from "./types";
import { apiName } from "../../aws_config"
import { API } from "aws-amplify";

const defaultInit = { response: true }; //return axios response object for less refactoring

export function fetchStays() {
  return {
    type: FETCH_STAYS,
    payload: API.get(apiName, "/stays", defaultInit),
  };
}

export function fetchStay(id) {
  return {
    type: FETCH_STAY,
    payload: API.get(apiName, `/stays/${id}`, defaultInit),
  };
}

export function updateStay(updatedStay) {
  return {
    type: UPDATE_STAY,
    payload: API.put(apiName, `/stays/${updatedStay.id}`, Object.assign(defaultInit, { body: updatedStay })),
  };
}

export function createUpdate(update) {
  return {
    type: CREATE_UPDATE,
    payload: Promise.resolve(update)
  };
}

export function fetchInsurancePlans(params) {
  return {
    type: FETCH_INSURANCE_PLANS,
    payload: API.get(apiName, "/insurancePlans", Object.assign(defaultInit, {
      queryStringParameters: params,
    })),
  };
}

export function fetchInsurancePlan(id) {
  return {
    type: FETCH_INSURANCE_PLAN,
    payload: API.get(apiName, `/insurancePlans/${id}`, defaultInit),
  };
}

export function fetchAlerts(params) {
  return {
    type: FETCH_ALERTS,
    payload: API.get(apiName, "/alerts", Object.assign(defaultInit, {
      queryStringParameters: params,
    })),
  };
}

export function fetchAlertsForStay(stayId) {
  return {
    type: FETCH_ALERTS,
    payload: API.get(apiName, `/stays/${stayId}/alerts`, defaultInit),
  };
}

export function fetchOrdersForStay(stayId) {
  return {
    type: FETCH_ORDERS,
    payload: API.get(apiName, `/stays/${stayId}/orders`, defaultInit),
  };
}

export function fetchNurseNotesForStay(stayId) {
  return {
    type: FETCH_NURSE_NOTES,
    payload: API.get(apiName, `/stays/${stayId}/nurseNotes`, defaultInit),
  };
}

export function fetchTherapyEventsForStay(stayId) {
  return {
    type: FETCH_THERAPY_EVENTS,
    payload: API.get(apiName, `/stays/${stayId}/therapyEvents`, defaultInit),
  };
}

export function fetchUpdates(params) {
  return {
    type: FETCH_UPDATES,
    payload: API.get(apiName, '/updates', Object.assign(defaultInit, { queryStringParameters: params }))
  };
}

export function setUpdateDateFilter({ startDate, endDate }) {
  return {
    type: SET_UPDATE_DATE_FILTER,
    payload: {
      startDate,
      endDate
    }
  }
}

export function setAlertDateFilter({ startDate, endDate }) {
  return {
    type: SET_ALERTS_DATE_FILTER,
    payload: {
      startDate,
      endDate
    }
  }
}

export function setStayDateFilter({ startDate, endDate }) {
  return {
    type: SET_STAY_DATE_FILTER,
    payload: {
      startDate,
      endDate
    }
  }
}
