import { Auth } from "aws-amplify";
import authConfig from './aws_exports';

export const apiName = "CaseManagementApi";

export const config = {
    Auth: {
        region: process.env.REACT_APP_USER_POOL_REGION || authConfig.region,
        userPoolId: process.env.REACT_APP_USER_POOL_ID || authConfig.userPoolId,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || authConfig.userPoolWebClientId,
    },
    API: {
        endpoints: [
            {
                name: apiName,
                endpoint: process.env.REACT_APP_API_BASE_URL || authConfig.apiBaseUrl,
                custom_header: async () => {
                    return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() }
                }
            }
        ]
    }
}