import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AlertsList from './AlertsList';
import AlertsSummary from './AlertsSummary';
import { connect } from 'react-redux';
import { asListSelector, filteredAlertsSelector, embedStays } from './store/selectors';
import { fetchAlerts, fetchStays, setAlertDateFilter } from './store/actions';
import { DateFilter } from './common/DateFilter';

export class AlertsPage extends Component {
    componentDidMount() {
        const { fetchAlerts, fetchStays } = this.props;
        typeof fetchStays === 'function' && fetchStays();
        typeof fetchAlerts === 'function' && fetchAlerts();
    }
    
    render() {
        return (
            <>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }} className="m-b-sm">
                    <h1 className='h2 text-primary' style={{ flex: '1 0 auto' }}>Alerts</h1>
                    <DateFilter 
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        setDateFilter={this.props.setAlertDateFilter}
                    />
                </div>
                <div className='m-b-sm'>
                    <AlertsSummary alerts={this.props.alerts} />
                </div>
                <div className='hpanel'>
                    <div className='panel-body no-padding'>
                        <AlertsList {...this.props} />
                    </div>
                </div>
            </>
        );
    }
};

AlertsPage.propTypes = {
    alerts: PropTypes.array
};

function mapStateToProps(state) {
    return {
        alerts: embedStays(asListSelector(filteredAlertsSelector))(state),
        startDate: state.alerts.filter.startDate,
        endDate: state.alerts.filter.endDate
    };
}

export default connect(mapStateToProps, { fetchAlerts, fetchStays, setAlertDateFilter })(AlertsPage);