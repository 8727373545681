import React from 'react';
import { connect } from 'react-redux';
import Modal from '../common/Modal';
import { Formik } from 'formik';
import moment from 'moment';
import AsyncSelect from 'react-select/lib/Async';
import { createFilter } from 'react-select';
import { fetchStays, createUpdate } from '../store/actions';
import { useFullName } from '../utilities';
import SingleDatePicker from '../common/SingleDatePicker';
import * as Yup from 'yup';

const doFilter = createFilter();
const loadStays = (inputValue) => {
    return new Promise(resolve => {
        fetchStays({ search: inputValue }).payload.then(result => {
            const values = result.data.map(stay => ({
                label: useFullName(stay),
                value: stay.id
            })).filter(v => !inputValue || doFilter(v, inputValue));
            resolve(values);
        });
    });
}

class CreateUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }
    openModal(open) {
        this.setState({ open });
    }
    render() {
        return (
            <>
                <button className='btn btn-primary' onClick={() => this.openModal(true)}>Create Update</button>
                <Modal open={this.state.open} onClose={() => this.openModal(false)} title="Create An Update">
                    <Formik
                        initialValues={{
                            patient: undefined,
                            date: moment()
                        }}
                        validationSchema={Yup.object().shape({
                            patient: Yup.string().required('Required'),
                            date: Yup.object().nullable().required('Required')
                        })}
                        onSubmit={(values, actions) => {
                            this.props.createUpdate({
                                dueDate: values.date.format('YYYY-MM-DD'),
                                stayId: values.patient
                            }).then(() => {
                                actions.setSubmitting(false);
                                this.openModal(false);
                            }, () => actions.setSubmitting(false));
                        }}
                        render={({ values, handleSubmit, isSubmitting, setFieldValue, setFieldTouched, touched, errors }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="control-label" htmlFor="patient">Patient Stay</label>
                                    <AsyncSelect id="patient"
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={loadStays}
                                        onChange={option => setFieldValue('patient', option.value)}
                                        onBlur={() => setFieldTouched('patient', true)} />
                                    {touched.patient && errors.patient && <div className="help-block text-danger">{errors.patient}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="date">Due Date</label>
                                    <div>
                                        <SingleDatePicker
                                            date={values.date}
                                            onDateChange={date => setFieldValue('date', date)}
                                            numberOfMonths={1}
                                            id="date"
                                        />
                                    </div>
                                    {touched.date && errors.date && <div className="help-block text-danger">{errors.date}</div>}
                                </div>
                                <button type='submit' className='btn btn-primary' disabled={isSubmitting}>Save</button>
                            </form>
                        )}
                    />
                </Modal>
            </>
        );
    }
}

export default connect(null, { createUpdate })(CreateUpdate);
