import React from 'react';

export default function Modal(props) {
    const { title, children, open, onClose, footer } = props;
    return open ? (
        <div className="modal-open">
            <div className="fade modal-backdrop in"></div>
            <div className="modal fade in" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" aria-label="Close" onClick={onClose}><span aria-hidden="true">&times;</span></button>
                        {title && <h4 className="modal-title">{title}</h4>}
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    {footer && <div className="modal-footer">
                        {footer}
                    </div>}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

Modal.defaultProps = {
    open: false
};