import React from 'react';
import SingleDatePicker from './SingleDatePicker';

export class DateFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
    }
    handleStartChange(startDate) {
        // TODO possibly fetch more data
        let endDate = this.props.endDate;
        if(this.props.endDate && startDate && startDate.isAfter(this.props.endDate)) endDate = startDate;
        this.props.setDateFilter({
            startDate,
            endDate
        });
    }
    handleEndChange(endDate) {
        // TODO possibly fetch more data
        this.props.setDateFilter({
            endDate,
            startDate: this.props.startDate
        });
    }
    render() {
        return (
            <div>
                <label className="m-r-xs">Date Range:</label>
                <SingleDatePicker id="startDueDate"
                    date={this.props.startDate}
                    onDateChange={this.handleStartChange}
                    placeholder="Start Date"
                    isOutsideRange={() => false}
                    showClearDate
                />
                {" - "}
                <SingleDatePicker id="endDueDate"
                    date={this.props.endDate}
                    onDateChange={this.handleEndChange}
                    placeholder="End Date"
                    isOutsideRange={(day) => day.isBefore(this.props.startDate, 'day')}
                    showClearDate
                />
            </div>
        );
    }
}