import React from 'react';
import PropTypes from 'prop-types';
import { sortBy as _sortBy } from 'lodash';
import Fuse from 'fuse.js';

const propTypes = {
    /**
     * Array of objects containing the data to diplay in the table
     */
    data: PropTypes.array,
    /**
     * Configuration for the table including headers, sorting, and search settings
     */
    config: PropTypes.shape({
        /**
         * The array of columns in the table
         */
        columns: PropTypes.arrayOf(PropTypes.shape({
            /** The display title for this column */
            title: PropTypes.string,
            /** The key in the data object who value will be used in the table (optional) */
            key: PropTypes.string,
            /**
             * A function to transform the data value. If a key was given, the parameter to the function will be the key.
             * If a key was not given, the parameter of the function will be the entire object for the row.
             */
            transformer: PropTypes.func,
            /** Whether or not this column is sortable */
            sortable: PropTypes.bool,
            /** The key of the data object to use for sorting. Use this if no key prop was given or the sort value differs from the display key */
            sortKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            /**
             * Similar to the transformer, the sortValue prop is used to transform the data into a sortable value.
             * For example, transform a date into a unix timestamp for proper date sorting.
             * Note that this function will always receive the full data object as a param.
             * */
            sortValue: PropTypes.func
        })),
        /**
         * Search settings for the table (optional)
         * When no search settings are given, then no search input appears.
         * There are additional search options available that are not defined in the shape. See fusejs.io for all search options available.
         */
        search: PropTypes.shape({
            /** Specify the keys of the data to search on. This *must* be specified in order to enable searching. */
            keys: PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    name: PropTypes.string,
                    weight: PropTypes.number
                })
            ]))
        }),
        /** The default sort tells the table to start in a sorted state */
        defaultSort: PropTypes.shape({
            /** The column to sort by on initial render */
            column: PropTypes.number,
            /** The direction to sort the column on initial render */
            direction: PropTypes.oneOf(['ascending', 'descending'])
        })
    }),
    /**
     * This function allows adding additonal props per row based on the row data.
     * The function takes a param, data, that is the row object from the data set given
     * and should return a set of props to apply to the tr element.
     * Typically this is used to apply `className` and `style` per row, but may be used to apply other props as needed.
     */
    rowStyler: PropTypes.func
};

/**
 * A generic data table that enables client-side searching and sorting
 */
export default class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: (props.config && props.config.defaultSort) || {},
            search: ''
        };
        this.sortBy = this.sortBy.bind(this);
        this.sort = this.sort.bind(this);
        this.search = this.search.bind(this);
    }

    sort(data = []) {
        const { config : { columns = [] } = {} } = this.props;
        const { sort: { column, direction }} = this.state;

        // Determine what to sort by, sortValue preferred over sortKey which is preferred over key
        const { key, sortValue, sortKey } = columns[column] || {};
        const sorter = sortValue ? sortValue : sortKey || key;

        // Only sort when given valid sorting information
        if(!direction || (data.length > 0 && column >= 0 && column < data[0].length) || !sorter)
            return data;

        const sorted = _sortBy(data, sorter);
        return direction === 'descending' ? sorted.reverse() : sorted;
    }

    sortBy(column) {
        this.setState(state => {
            return {
                sort: {
                    column,
                    direction: state.sort.column === column && state.sort.direction === 'ascending' ? 'descending' : 'ascending'
                }
            };
        });
    }

    search(data = []) {
        const { search: searchTerm } = this.state;
        const { config: { search: searchOptions } = {} } = this.props;
        if (!searchTerm || !searchOptions || !searchOptions.keys) return data;
        return new Fuse(data, {
            threshold: 0.3,
            tokenize: true,
            ...searchOptions
        }).search(searchTerm);
    }

    render() {
        const { data = [], config: { columns = [], search: { keys: searchable } = {} } = {}, rowStyler } = this.props;
        const { sort: { column, direction }, search } = this.state;
        const tableData = this.sort(this.search(data));
        return (
            <>
                {searchable &&
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <div className="input-group input-group-sm p-xs" style={{ flexBasis: '300px' }}>
                            <span className="input-group-addon"><i className={'fa fa-search'}/></span>
                            <input type='text'
                                className={'form-control'}
                                value={search}
                                onChange={(e) => this.setState({ search: e.target.value })} />
                        </div>
                    </div>
                }
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                {columns.map(({ title, sortable }, i) => {
                                    return (
                                        <th key={`${title}${i}`}
                                            onClick={sortable ? () => this.sortBy(i) : null}
                                            className={sortable ? 'clickable' : null} >
                                            {title} {sortable && <i className={`fa fa-sort${column === i && '-'}${direction === 'ascending' ? 'asc' : 'desc'}`}/>}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((data, i) => {
                                const rowProps = rowStyler && typeof rowStyler === 'function' && rowStyler(data);
                                return (
                                    <tr key={data && data.id ? data.id : `${data}${i}`} {...rowProps}>
                                        {columns.map(({ key, transformer }, i) => {
                                            let value = key ? data[key] : data;
                                            if (typeof transformer === 'function') value = transformer(value);
                                            return <td key={`${key ? key : value}${i}`}>{value}</td>
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

Table.propTypes = propTypes;
