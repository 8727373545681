import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Amplify, { Auth } from 'aws-amplify';
import {  ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import UpdatesPage from './UpdatesPage';
import PatientPage from './PatientPage';
import AlertsPage from './AlertsPage';
import PatientsPage from './PatientsPage';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { config } from './aws_config'
import AuthTheme from './auth/AuthTheme';

Amplify.configure(config);



export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {userEmail: null};
  }
  doLink(path) {
    const {
      baseRoute = ''
    } = this.props;
    return `${baseRoute}${path}`;
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(
      value => this.setState({userEmail: value.attributes.email})
    );
  }

  logout() {
    Auth.signOut();
  }


  render() {
    const userEmail = this.state.userEmail;
    return (
      <Router>
        <>
          <div id="header" className="clearfix">
            <div className="color-line"></div>
            <nav className="navbar" role="navigation">
              <div className="navbar-header">
                <a href="/" className="navbar-brand">
                  <img src="/static/images/medasync-logo.svg" alt="Medasync Logo" />
                </a>
              </div>
              <div className="collapse navbar-collapse" id="Navbar">
                <ul className="nav navbar-nav navbar-right no-borders">
                  <li>
                    { userEmail && 
                      <p style={{
                        marginTop: '10%',
                        marginRight: '10pt'
                      }}>Hello, {userEmail}</p>
                    }
                  </li>
                  <li>
                    <button className='btn btn-primary' style={{
                      marginTop: '10%',
                      marginRight: '10pt',
                      color: 'white'
                    }} onClick={() => this.logout()}>Sign Out</button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <nav id="menu" className="ps ps--theme_default" data-ps-id="6162f2df-a76d-9b56-4553-28096a4d777e">
            <ul className="nav" id="side-menu" role="menu">
              <li role="menuitem">
                <NavLink exact to={this.doLink('/')} activeClassName={'active'}>
                  <i className="fa fa-fw fa-flag"></i>
                  Updates
                </NavLink>
              </li>
              <li role="menuitem">
                <NavLink exact to={this.doLink('/patients')} activeClassName={'active'}>
                  <i className="fa fa-fw fa-user"></i>
                  Patients
                </NavLink>
              </li>
              <li role="menuitem">
                <NavLink to={this.doLink('/alerts')} activeClassName={'active'}>
                  <i className="fa fa-fw fa-bell"></i>
                  Alerts
                </NavLink>
              </li>
            </ul>
          </nav>

          <div id="Wrapper">
            <main className="content">
              <Route path={this.doLink('/')} exact component={UpdatesPage} />
              <Route path={this.doLink('/alerts')} exact component={AlertsPage} />
              <Route path={this.doLink('/patients')} exact component={PatientsPage} />
              <Route path={this.doLink('/patient/:id')} component={PatientPage} />
            </main>
            <footer>
              <span>{'\u00A9'} 2018 - MedaSync </span>
            </footer>
          </div>
        </>
      </Router>      
    );
  }
}

App.propTypes = {
  baseRoute: PropTypes.string
};

export default withAuthenticator(
  App,
  false,
  [
    <ConfirmSignIn />,
    <ConfirmSignUp />,
    <ForgotPassword />,
    <RequireNewPassword />,
    <SignIn />,
    <VerifyContact />
  ],
  null,
  AuthTheme
);
