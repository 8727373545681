import React from 'react';
import { connect } from 'react-redux';
import { staysSelector } from '../store/selectors';
import { Link } from 'react-router-dom';
import { useFullName } from '../utilities';

function PatientLink({ stay }) {
    return stay ? <Link to={`/patient/${stay.id}`}>{useFullName(stay)}</Link> : null;
}

function mapStateToProps(_, initialProps) {
    const { stayId } = initialProps;
    return state => {
        return {
            stay: staysSelector(state)[stayId]
        };
    }
}
export default connect(mapStateToProps)(PatientLink);