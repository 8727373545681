import React from 'react';
import { connect } from 'react-redux';
import { getInsurancePlansForPayerSelector } from '../store/selectors';
import Checkbox from '../common/Checkbox';
import { Formik, FieldArray } from 'formik';
import { updateStay } from '../store/actions';

export class ServiceSummary extends React.Component {
    render() {
        const { insurancePlans, stay = {}, updateStay } = this.props;
        const billableItems = insurancePlans.reduce((result, plan) => {
            return [
                ...result,
                ...plan.carveOutMedications || []
            ];
        }, []);
        return insurancePlans && insurancePlans.length > 0 ? (
            <>
                <Formik
                    initialValues={{
                        medicallyComplexCareEventIds: stay.medicallyComplexCareEventIds || [],
                        carveOutMedicationIds: stay.carveOutMedicationIds || []
                    }}
                    onSubmit={(values, actions) => {
                        const updatedStay = {
                            ...stay,
                            ...values
                        };
                        updateStay(updatedStay).then(() => actions.setSubmitting(false));
                    }}
                    render={({ values, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <div className='row'>
                                <FieldArray
                                    name="medicallyComplexCareEventIds"
                                    render={arrayHelpers => (
                                        <div className='col-sm-7 col-md-6'>
                                                {insurancePlans.map(plan => {
                                                    return (
                                                        <div key={plan.id}>
                                                            <h3 className='h4'>{plan.careLevel}{plan.requirements ? ` (${plan.requirements})` : ''}:</h3>
                                                            {(plan.medicallyComplexCareEvents || []).map(event => {
                                                                return (
                                                                    <Checkbox 
                                                                        key={event.id} 
                                                                        label={event.description}
                                                                        name='medicallyComplexCareEventIds'
                                                                        value={event.id}
                                                                        checked={values.medicallyComplexCareEventIds.includes(event.id)}
                                                                        onChange={e => {
                                                                            if (e.target.checked) arrayHelpers.push(event.id);
                                                                            else {
                                                                                const idx = values.medicallyComplexCareEventIds.indexOf(event.id);
                                                                                arrayHelpers.remove(idx);
                                                                            }
                                                                        }} />
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                    )}
                                />
                                <FieldArray
                                    name="carveOutMedicationIds"
                                    render={arrayHelpers => (
                                        <div className='col-sm-5 col-md-6'>
                                            <h3 className='h4'>Other billable items (carve outs & exclusions):</h3>
                                            {billableItems.map(item => {
                                                return (
                                                    <Checkbox key={item.id} 
                                                    label={item.description}
                                                    name='carveOutMedicationIds'
                                                    value={item.id}
                                                    checked={values.carveOutMedicationIds.includes(item.id)}
                                                    onChange={e => {
                                                        if (e.target.checked) arrayHelpers.push(item.id);
                                                        else {
                                                            const idx = values.carveOutMedicationIds.indexOf(item.id);
                                                            arrayHelpers.remove(idx);
                                                        }
                                                    }} />
                                                );
                                            })}
                                        </div>
                                    )}
                                />
                            </div>
                            <button type='submit' className='btn btn-primary' disabled={isSubmitting}>Save</button>
                        </form>
                    )}
                />
            </>
        ): 'Oops, we couldn\'t find the patient\'s plan information at this time.';
    }
}

function mapStateToProps(state, ownProps) {
    const { stay: { insurancePayerName } = {}} = ownProps;
    const insurancePlans = getInsurancePlansForPayerSelector(insurancePayerName)(state);
    return {
        insurancePlans: insurancePlans || []
    };
}
export default connect(mapStateToProps, { updateStay })(ServiceSummary);