import { createSelector } from 'reselect';
import moment from 'moment';
import { normalize } from '../reducers';

export const staysSelector = state => state.stays.all
export const updatesSelector = state => state.updates.all
export const insurancePlansSelector = state => state.insurancePlans.all
export const alertsSelector = state => state.alerts.all
export const ordersSelector = state => state.orders.all
export const nurseNotesSelector = state => state.nurseNotes.all
export const therapyEventsSelector = state => state.therapyEvents.all

export const filteredUpdatesSelector = state => {
  const updatesMap = updatesSelector(state);
  const { filter } = state.updates;
  return normalize(Object.values(updatesMap).filter(update => {
    const dueDate = moment(update.dueDate);
    return (!filter.startDate || dueDate.isSameOrAfter(filter.startDate, 'day')) &&
      (!filter.endDate || dueDate.isSameOrBefore(filter.endDate, 'day'));
  }));
};

export const embedStays = (selector) =>  createSelector(
  selector,
  staysSelector,
  (list = [], staysMap) => {
    return list.map(item => {
      return item.stayId ? { ...item, stay: staysMap[item.stayId] } : item;
    });
  }
)

export const filteredAlertsSelector = createSelector(
  alertsSelector,
  state => state.alerts.filter.startDate,
  state => state.alerts.filter.endDate,
  (alertsMap, startDate, endDate) => {
    return normalize(Object.values(alertsMap).filter(alert => {
      const date = moment(alert.date);
      return (!startDate || date.isSameOrAfter(startDate, 'day')) &&
        (!endDate || date.isSameOrBefore(endDate, 'day'));
    }));
  }
);

export const filteredStaysSelector = createSelector(
  staysSelector,
  state => state.stays.filter.startDate,
  state => state.stays.filter.endDate,
  (staysMap, startDate, endDate) => {
    return normalize(Object.values(staysMap).filter(stay => {
      const date = moment(stay.admitDate);
      return (!startDate || date.isSameOrAfter(startDate, 'day')) &&
        (!endDate || date.isSameOrBefore(endDate, 'day'));
    }));
  }
);

export const asListSelector = selector => createSelector(
  selector,
  (normalizedData) => {
    return Object.values(normalizedData);
  }
);

export const staysListSelector = createSelector(
  filteredStaysSelector,
  insurancePlansSelector,
  (stays = {}, insurancePlans = {}) => {
    return Object.values(stays).map(stay => {
      return {
        ...stay,
        billingInsurancePlan: insurancePlans[stay.billingInsurancePlanId],
        deliveredInsurancePlan: insurancePlans[stay.deliveredInsurancePlanId],
      };
    })
  }
);

export const updatesListSelector = state => {
  const staysMap = normalize(staysListSelector(state));
  return Object.values(filteredUpdatesSelector(state)).map(update => {
    return {
      ...update,
      stay: staysMap[update.stayId] || {}
    };
  });
};

export const getInsurancePlansForPayerSelector = payerName => createSelector(
  insurancePlansSelector,
  insurancePlans => {
    return Object.values(insurancePlans).filter(plan => plan.payerName === payerName).sort((a, b) => {
      return a.rank - b.rank;
    });
  }
)

export const getAlertsForStaySelector = (stayId) => createSelector(
  alertsSelector,
  (alerts) => {
    return Object.values(alerts).filter(alert => alert.stayId === stayId);
  }
);

export const getOrdersForStaySelector = (stayId) => createSelector(
  ordersSelector,
  (orders) => {
    return Object.values(orders).filter(order => order.stayId === stayId);
  }
);

export const getNurseNotesForStaySelector = (stayId) => createSelector(
  nurseNotesSelector,
  (nurseNotes) => {
    return Object.values(nurseNotes).filter(order => order.stayId === stayId);
  }
);

export const getTherapyEventsForStaySelector = stayId => createSelector(
    therapyEventsSelector,
    (therapyEvents) => {
        return Object.values(therapyEvents).filter(order => order.stayId === stayId);
    }
);

export const getTherapyNotesForStaySelector = stayId => createSelector(
    getTherapyEventsForStaySelector(stayId),
    (therapyEvents = []) => {
        return therapyEvents.filter(event => {
            return !!event.notes;
        });
    }
);
 export const getTotalTherapyMinutesByDaySelector = (therapySelector) => createSelector(
   therapySelector,
   (therapyEvents) => {
    return therapyEvents.reduce((result, event) => {
      const { date, durationInMinutes} = event;
      const previousTotal = result[date] || 0;
      return {
        ...result,
        [date]: previousTotal + durationInMinutes
      }
    }, {});
   }
 );

 export const generateTherapyGraphForStaySinceDate = (stayId, startDate) => {
  const therapyStaySelector = getTherapyEventsForStaySelector(stayId);
  const selector = getTotalTherapyMinutesByDaySelector(therapyStaySelector);
  return createSelector(
    selector,
    (therapyMinutesByDay) => {
      const startMoment = moment(startDate);
      const days = Math.ceil(moment.duration(moment().diff(startMoment)).asDays());
      return [...new Array(days)].map((_, i) => {
        const forDay = startMoment.clone().add(i, 'day').format('YYYY-MM-DD');
        const minutes = therapyMinutesByDay[forDay] || 0;
        return {
          date: forDay,
          minutes
        }
      });
    }
  )
}
