import React from 'react';

export default (props) => {
    const { label, ...rest } = props;
    return (
        <div className="checkbox">
            <label>
            <input type="checkbox" {...rest} /> {label}
            </label>
        </div>
    )
};