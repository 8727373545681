import { FETCH_ORDERS } from '../actions/types';
import { normalize } from './index';

const initialState = {
    all: {}
};

export default function(state = initialState, action) {
    switch(action.type) {
        case `${FETCH_ORDERS}_FULFILLED`:
            return {
                ...state,
                all: {
                    ...state.all,
                    ...normalize(action.payload.data)
                }
            };
        default:
            return state;
    }
}
