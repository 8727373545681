import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StaysList from './patient/StaysList';
import { staysListSelector } from './store/selectors';
import { fetchStays, fetchInsurancePlans, setStayDateFilter } from './store/actions';
import { DateFilter } from './common/DateFilter';

export class StaysPage extends Component {
    componentDidMount() {
        const { fetchStays, fetchInsurancePlans, startDate } = this.props;
        typeof fetchStays === 'function' && fetchStays({ startDate: startDate && startDate.format('YYYY-MM-DD') });
        typeof fetchInsurancePlans === 'function' && fetchInsurancePlans();
    }
    
    render() {
        return (
            <>
                <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }} className="m-b-sm">
                    <h1 className='h2 text-primary' style={{ flex: '1 0 auto' }}>Patients</h1>
                    <DateFilter endDate={this.props.endDate}
                        startDate={this.props.startDate}
                        setDateFilter={this.props.setStayDateFilter} />
                </div>
                <div className='hpanel'>
                    <div className='panel-body no-padding'>
                        <StaysList {...this.props} />
                    </div>
                </div>
            </>
        );
    }
};

StaysPage.propTypes = {
    stays: PropTypes.array
};

function mapStateToProps(state) {
    return {
        stays: staysListSelector(state),
        startDate: state.stays.filter.startDate,
        endDate: state.stays.filter.endDate
    };
}

export default connect(mapStateToProps, { fetchStays, fetchInsurancePlans, setStayDateFilter })(StaysPage);