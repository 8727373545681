import React from 'react';
import { connect } from 'react-redux';
import { fetchOrdersForStay } from '../store/actions';
import { getOrdersForStaySelector } from '../store/selectors';
import moment from 'moment';
import Table from '../common/Table';

const config = {
    columns: [{
        title: 'Date',
        key: 'date',
        transformer: (data) => moment(data).format('M/DD/YYYY'),
        sortValue: (data) => moment(data.date).unix(),
        sortable: true
    }, {
        title: 'Category',
        key: 'category',
        sortable: true
    }, {
        title: 'Status',
        key: 'status',
        sortable: true
    }, {
        title: 'Description',
        key: 'description',
        sortable: true
    }],
    defaultSort: {
        column: 0,
        direction: 'descending'
    }
};

class Orders extends React.Component {
    componentDidMount() {
        const { stay, fetchOrdersForStay } = this.props;
        stay && fetchOrdersForStay(stay.id);
    }
    render() {
        const { orders = [] } = this.props;
        return orders.length > 0 ?
            <Table data={orders} config={config} /> : 'There are no orders for this patient.';
    }
}
function mapStateToProps(state, ownProps) {
    const { stay } = ownProps;
    return {
        orders: stay && stay.id ? getOrdersForStaySelector(stay.id)(state) : []
    };
}

export default connect(mapStateToProps, { fetchOrdersForStay })(Orders);